import React from 'react'
import { Helmet } from 'react-helmet'
import PropTypes from "prop-types"
import { graphql, useStaticQuery } from 'gatsby'

export default function Seo(props) {
  const { title, description, meta, lang } = props
  const data = useStaticQuery(
    graphql`
    query {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
    `
  )
  const metaDescription = description || data.site.siteMetadata.description
  return (
    <Helmet
    htmlAttributes={{lang}}
    title={title} 
    titleTemplate={`%s | ${data.site.siteMetadata.title}`}
    meta={[
      {
        name: 'description',
        content: metaDescription,
      },
      {
        name: 'og:title',
        content: title,
      },
      {
        name: 'og:description',
        content: metaDescription,
      }
    ].concat(meta)}
    />
  )
}
Seo.defaultProps = {
  lang: 'es',
  meta: [],
  description: '',
}
Seo.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
}