export const isBrowser = () => typeof window !== "undefined"
export const getUser = () => {
  if (!isBrowser()) {
    return;
  } else {
    if (window.localStorage.getItem("gatsbyUser")) {
      return JSON.parse(window.localStorage.getItem("gatsbyUser"))
    } else {
      return {}
    }
  }
}

const setUser = user => {
  if (!isBrowser()) {
    return;
  }
  window.localStorage.setItem("gatsbyUser", JSON.stringify(user))

}
export const handleLogin = ({ username, password }) => {
  if (!isBrowser()) {
    return
  }
  if (username === `admin` && password === `admincolonial`) {
    return setUser({
      username: `admin`,
      name: `admin`,
      email: `admin@example.org`,
    })
  }
  return false
}

export const isLoggedIn = () => {
  if (!isBrowser()) {
    return;
  }
  const user = getUser()
  return !!user.username
}
export const logout = callback => {
  if (!isBrowser()) {
    return;
  }
  setUser({})
  callback()
}